import { Lightbulb } from '@aurelius/icons'
import { GlobalRecommendation, Hashtag, Recommendation } from '@aurelius/models'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import { ErrorBoundary } from 'react-error-boundary'
import { Box } from '@chakra-ui/react'
import { Card, CardProps } from './Card'
import { Checkbox } from './Checkbox'
import { ErrorFallback } from './ErrorFallback'
import { SectionBox, GreenSpot, RedSpot, TitleBox } from './Global.styles'
import { Tag } from './Tag'

export type StatusOption = { label: any; value: string }
export const statusOptions: StatusOption[] = [
  {
    value: 'not_started',
    label: (
      <Box>
        <RedSpot />
        <span>Not Started</span>
      </Box>
    ),
  },
  {
    value: 'in_progress',
    label: 'In Progress',
  },
  {
    value: 'completed',
    label: (
      <Box>
        <GreenSpot />
        <span>Complete</span>
      </Box>
    ),
  },
  {
    value: 'none',
    label: 'None',
  },
]

const P = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  color: var(--colors-typography-meta);
  margin-bottom: 5px;
`
const CrossTag = styled(Tag)`
  display: flex;
`
const DataP = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
  letter-spacing: 0.19px;
  color: var(--colors-typography-labels-sub);
`

const MetaDataBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
`
export const RecommendationCard = ({
  recommendation,
  checked = false,
  check,
  readOnly = false,
  children,
  ...rest
}: React.PropsWithChildren<
  {
    recommendation: Recommendation | GlobalRecommendation
    checked?: boolean
    check?: (v: { id: string } | null) => void
    readOnly?: boolean
  } & CardProps
>) => {
  const id = recommendation.id || recommendation._id
  const canCheck = !readOnly && check
  return (
    <Card
      padding="24px"
      marginBottom="8px"
      backgroundColor="var(--colors-layout-background)"
      {...rest}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SectionBox width="100%" alignItems="center" flexWrap="wrap">
          <TitleBox
            display="flex"
            flexDirection="column"
            flex="60%"
            justifyItems="space-between"
          >
            <Box display="flex" marginBottom="19px" alignItems="flex-start">
              {canCheck && check && (
                <Checkbox
                  name={`${id}`}
                  checked={checked}
                  onChange={(): void => {
                    if (checked) {
                      check(null)
                    } else {
                      check({ id })
                    }
                  }}
                />
              )}
              <Box marginLeft={canCheck ? '16px' : ''}>{children}</Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              overflow="auto"
              width="100%"
              marginLeft={canCheck ? '32px' : ''}
            >
              {recommendation.isGlobal && (
                <CrossTag marginRight="4px" marginLeft="4px" marginBottom="8px">
                  <Lightbulb
                    title="Global Recommendation"
                    width={18}
                    height={18}
                  />{' '}
                  Cross Project Recommendation
                </CrossTag>
              )}
              {((recommendation.hashtags || []) as Hashtag[]).map((tag) => (
                <Tag
                  key={tag._id}
                  backgroundColor={tag?.display?.backgroundColor}
                  foregroundColor={tag?.display?.foregroundColor}
                  marginRight="4px"
                  marginLeft="4px"
                  marginBottom="8px"
                >
                  #{tag.name}
                </Tag>
              ))}
            </Box>
          </TitleBox>
          <Box flex="40%" display="flex">
            <Box
              display="flex"
              flex="1"
              flexDirection="row"
              justifyContent="space-between"
            >
              <MetaDataBox>
                <P>Status:</P>
                <DataP>
                  {
                    statusOptions.find((e) => e.value === recommendation.status)
                      ?.label
                  }
                </DataP>
              </MetaDataBox>
              <MetaDataBox>
                <P>Type:</P>
                <DataP>{recommendation.recType}</DataP>
              </MetaDataBox>
              <MetaDataBox>
                <P>Created:</P>
                <DataP>
                  {recommendation?.createdAt != null
                    ? format(
                        new Date(recommendation?.createdAt),
                        'MMM dd, yyyy',
                      )
                    : null}
                </DataP>
              </MetaDataBox>
            </Box>
          </Box>
        </SectionBox>
      </ErrorBoundary>
    </Card>
  )
}

RecommendationCard.displayName = 'RecommendationCard'
