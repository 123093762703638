import * as yup from 'yup'
import { removeURLs } from './purchase'

const key = yup.string().trim().required('key is required.')

export const invitedUser = yup.object().shape({
  key,
  firstname: yup
    .string()
    .trim()
    .required('First Name is required.')
    .transform(removeURLs),
  lastname: yup
    .string()
    .trim()
    .required('Last Name is required.')
    .transform(removeURLs),
  password: yup
    .string()
    .trim()
    .min(8, 'Password must be at least 8 characters.')
    .required('Password is required.'),
  acceptedUserTOS: yup
    .boolean()
    .required()
    .oneOf([true], 'You must accept the Terms of Service.'),
})
export const invitedUserSSO = yup.object().shape({
  key,
  firstname: yup
    .string()
    .trim()
    .required('First Name is required.')
    .transform(removeURLs)
    .transform(removeURLs),
  lastname: yup
    .string()
    .trim()
    .required('Last Name is required.')
    .transform(removeURLs),
  acceptedUserTOS: yup
    .boolean()
    .required()
    .oneOf([true], 'You must accept the Terms of Service.'),
})

export const invitedUserForm = yup.object().shape({
  firstname: yup
    .string()
    .trim()
    .required('First Name is required.')
    .transform(removeURLs),
  lastname: yup
    .string()
    .trim()
    .required('Last Name is required.')
    .transform(removeURLs),
  password: yup
    .string()
    .trim()
    .min(8, 'Password must be at least 8 characters.')
    .required('Password is required.'),
  acceptedUserTOS: yup
    .boolean()
    .required('You must accept the Terms of Service.')
    .oneOf([true], 'You must accept the Terms of Service.'),
})
export const invitedUserSSOForm = yup.object().shape({
  firstname: yup
    .string()
    .trim()
    .required('First Name is required.')
    .transform(removeURLs),
  lastname: yup
    .string()
    .trim()
    .required('Last Name is required.')
    .transform(removeURLs),
  acceptedUserTOS: yup
    .boolean()
    .required('You must accept the Terms of Service.')
    .oneOf([true], 'You must accept the Terms of Service.'),
})

export const inviteKey = yup.object().shape({
  key,
})

export const addMembers = yup.object().shape({
  members: yup
    .array()
    .required()
    .of(
      yup.object().shape({
        email: yup.string().required().email().lowercase(),
        role: yup.string().required(),
      }),
    ),
  accessLevel: yup.object().shape({
    type: yup
      .string()
      .required('Type is required.')
      .oneOf(['everything', 'specific']),
    ids: yup.array().of(yup.string().required()).required(),
  }),
})

export const editMember = yup.object().shape({
  memberEmail: yup.string().required().email().lowercase(),
  fromRole: yup.string().oneOf(['admin', 'team_member', 'read_only']),
  toRole: yup.string().oneOf(['admin', 'team_member', 'read_only']),
  accessLevel: yup.object().shape({
    type: yup
      .string()
      .required('Type is required.')
      .oneOf(['everything', 'specific']),
    ids: yup.array().of(yup.string().required()).required(),
  }),
})

export type invitedUserFormType = yup.InferType<typeof invitedUserForm>
export type invitedUserSSOFormType = yup.InferType<typeof invitedUserSSOForm>
export type invitedUserType = yup.InferType<typeof invitedUser>
export type invitedUserSSOType = yup.InferType<typeof invitedUserSSO>
export type inviteKeyType = yup.InferType<typeof inviteKey>
export type addMembersType = yup.InferType<typeof addMembers>
export type editMemberType = yup.InferType<typeof editMember>

export const editMemberPermissions = yup.object().shape({
  userId: yup.string().required(),
  enabled: yup.boolean().required(),
})

export type editMemberPermissionsType = yup.InferType<
  typeof editMemberPermissions
>
