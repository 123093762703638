import { Span } from './Typography'
import { Box } from '@chakra-ui/react'
import Link from 'next/link'
import styled from '@emotion/styled'
import { Checkbox } from './Checkbox'
import { Insight, Hashtag, GlobalInsight } from '@aurelius/models'
import { Lightbulb } from '@aurelius/icons'
import { Tag } from './Tag'
import { format } from 'date-fns'
import { Card, CardProps } from './Card'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './ErrorFallback'

const P = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  color: var(--colors-typography-meta);
  margin-bottom: 5px;
`
const DataP = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
  letter-spacing: 0.19px;
  color: var(--colors-typography-labels-sub);
`

const CrossTag = styled(Tag)`
  display: flex;
`

const MetaDataBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
`

const Wrapper = styled(Card)`
  padding: ${(p) => p.padding ?? '24px'};
  width: ${(p) => p.width ?? '100%'};
  margin-bottom: ${(p) => p.marginBottom ?? '8px'};
  background-color: ${(p) =>
    p.backgroundColor ?? 'var(--colors-layout-background)'};

  &:hover {
    border-color: var(--colors-card-borders-hover);
  }
`

export const InsightCard = ({
  insight,
  checked = false,
  check,
  withMetadata,
  readOnly = false,
  children,
  title,
  titleLink,
  ...rest
}: React.PropsWithChildren<
  {
    insight: Insight | GlobalInsight
    checked?: boolean
    check?: (v: { id: string; isGlobal: boolean } | null) => void
    withMetadata: boolean
    readOnly?: boolean
    title?: string
    titleLink?: string
  } & CardProps
>) => {
  const id = insight?.id || insight?._id
  const canCheck = !readOnly && check
  return (
    <Wrapper {...rest}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Box
          display="flex"
          flexDirection="row"
          flex="1 1"
          justifyContent="space-between"
          alignItems="flex-start"
          backgroundColor="transparent"
          width="100%"
          flexWrap="wrap"
        >
          <Box
            display="flex"
            flexDirection="column"
            flex={withMetadata || title ? '60%' : '100%'}
          >
            <Box display="flex" marginBottom="12px" alignItems="flex-start">
              {canCheck && check && (
                <Checkbox
                  name={`${id}`}
                  checked={checked}
                  onChange={(): void => {
                    if (checked) {
                      check(null)
                    } else {
                      check({ id, isGlobal: !!insight?.isGlobal })
                    }
                  }}
                />
              )}
              <Box marginLeft={canCheck ? '16px' : ''}>{children}</Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              marginLeft={canCheck ? '32px' : ''}
              overflow="auto"
            >
              {insight?.isGlobal && (
                <CrossTag marginRight="4px" marginLeft="4px" marginBottom="8px">
                  <Lightbulb
                    title="Global Insights"
                    width={20}
                    height={20}
                    marginTop="-2px"
                  />{' '}
                  Cross Project Insight
                </CrossTag>
              )}
              {((insight?.hashtags || []) as Hashtag[]).map((tag) =>
                tag?._id ? (
                  <Tag
                    key={tag?._id}
                    backgroundColor={tag?.display?.backgroundColor}
                    foregroundColor={tag?.display?.foregroundColor}
                    marginRight="4px"
                    marginLeft="4px"
                    marginBottom="8px"
                  >
                    #{tag?.name}
                  </Tag>
                ) : null,
              )}
            </Box>
          </Box>
          {(withMetadata || title) && (
            <Box display="flex" flexDirection="column" flex="40%">
              {withMetadata && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <MetaDataBox>
                    <P>Priority:</P>
                    <DataP>{insight?.priority}</DataP>
                  </MetaDataBox>
                  <MetaDataBox>
                    <P>Type:</P>
                    <DataP>{insight?.insType}</DataP>
                  </MetaDataBox>
                  <MetaDataBox>
                    <P>Created:</P>
                    <DataP>
                      {insight?.createdAt &&
                        format(new Date(insight?.createdAt), 'MMM dd, yyyy')}
                    </DataP>
                  </MetaDataBox>
                </Box>
              )}
              {title && (
                <Box
                  display="flex"
                  flexDirection="column"
                  marginTop="24px"
                  alignItems="baseline"
                >
                  <P>Project:</P>
                  {titleLink ? (
                    <Link href={titleLink} className="anchor">
                      <Span fontSize="14px" color="inherit">
                        {title}
                      </Span>
                    </Link>
                  ) : (
                    <Span fontSize="14px">{title}</Span>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </ErrorBoundary>
    </Wrapper>
  )
}

InsightCard.displayName = 'InsightCard'
