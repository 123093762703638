import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  useCallback,
  KeyboardEvent,
} from 'react'
import styled from '@emotion/styled'
import { pick, omit } from '@styled-system/props'
import { Box, BoxProps } from '@chakra-ui/react'
import { MemoFDE } from './MemoFDE'
import { Spinner } from './Spinner'

export interface CheckboxItselfProps {
  checked: boolean
  className?: string
  onChange?: (value: boolean) => void
  dataTestId?: string
  isLoading?: boolean
  checkboxStyle?: any
}

export interface HiddenCheckboxProps {
  checked: boolean
}

const HiddenCheckbox = styled.input<HiddenCheckboxProps>`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:focus + div {
    box-shadow: 0 0 2px 1px var(--colors-typography-anchor);
  }
`
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`
interface StyledCheckbox {
  checked: boolean
  className?: string
}

const StyledCheckbox = styled.div<StyledCheckbox>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props): string => (props.checked ? '#003145' : 'white')};
  border: ${(props): string =>
    props.checked
      ? '1px solid transparent'
      : '1px solid var(--colors-inputs-checkbox-background)'};
  border-radius: 3px;
  padding-top: 0;

  & svg {
    visibility: ${(props): string => (props.checked ? 'visible' : 'hidden')};
  }
`
const CheckboxContainer = styled.div`
  display: flex;
  position: relative;
  margin-right: 4px;
`

type InputType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

const StyledLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  line-height: 18px;
  cursor: ${(props: { disabled?: boolean; readOnly?: boolean }): string =>
    props.disabled || props.readOnly ? 'not-allowed' : 'pointer'};
`

type Props = CheckboxItselfProps &
  Omit<InputType, 'onChange'> &
  Omit<BoxProps, 'onChange'>

const Component = ({
  onChange,
  className,
  children,
  checked,
  dataTestId,
  isLoading,
  style = {},
  checkboxStyle = {},
  ...props
}: Props) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const { key } = event
      if (key === 'Enter' || key === ' ') {
        //@ts-expect-error there is no HTMLCheckboxElement
        onChange?.(!event.target.checked)
      }
    },
    [onChange],
  )

  return (
    <Box
      onClick={(event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation?.()
      }}
      className={className}
      style={style}
      {...pick(props)}
    >
      <StyledLabel disabled={props.disabled} readOnly={props.readOnly}>
        <>
          <CheckboxContainer>
            <HiddenCheckbox
              {...omit(props)}
              type="checkbox"
              onChange={(e) => {
                onChange?.(e.target.checked)
              }}
              onKeyDown={handleKeyDown}
              checked={checked}
            />
            {isLoading ? (
              <Spinner height="16px" width="16px" />
            ) : (
              <StyledCheckbox
                checked={checked as boolean}
                data-test-id={dataTestId}
                aria-hidden="true"
                style={checkboxStyle}
              >
                <Icon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </Icon>
              </StyledCheckbox>
            )}
          </CheckboxContainer>
          {children}
        </>
      </StyledLabel>
    </Box>
  )
}
Component.displayName = 'Checkbox'

export const Checkbox = MemoFDE<Props>(Component)
