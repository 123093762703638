import { Replay } from '@sentry/replay'

if (process.env.SENTRY_DSN == null) {
  console.error('No SENTRY_DSN!')
} else if (process.env.API_BASE == null) {
  console.error('No API_BASE!')
} else if (process.env.HP_BASE == null) {
  console.error('No HP_BASE!')
} else if (process.env.WSS_BASE == null) {
  console.error('No WSS_BASE!')
}

const isProd = process.env.NODE_ENV === 'production'
const isQA = isProd && (process.env.COOKIE_DOMAIN ?? '').indexOf('.qa.') > -1

export const sentryConfig = {
  dsn: process.env.SENTRY_DSN,
  environment:
    process.env.ENVIRONMENT != null
      ? process.env.ENVIRONMENT
      : process.env.NODE_ENV,
  integrations: [
    new Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        process.env.API_BASE ?? '',
        process.env.HP_BASE ?? '',
        process.env.WSS_BASE ?? '',
      ],
      networkCaptureBodies: true,
      stickySession: true,
    }),
  ],
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    process.env.API_BASE ?? '',
    process.env.HP_BASE ?? '',
    process.env.WSS_BASE ?? '',
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  debug: isQA,
}
