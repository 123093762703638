import {
  useState,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react'
import styled from '@emotion/styled'
import { Box } from '@chakra-ui/react'
import { DualSlider } from '../DualSlider'
import { Slider } from '../Slider'
import { Button } from '../Button'
import MaskedInput from 'react-text-mask'
import { PlayBlueIcon } from '../svg/PlayBlueIcon'
import { PauseBlueIcon } from '../svg/PauseBlueIcon'
import { Clip, ClipModes } from '@aurelius/models'
import { zeroTime } from './ClipEditor'

const StartTimeInput = styled(MaskedInput as any)`
  border: 4px solid #148306 !important;
  border-radius: 8px !important;
`

const EndTimeInput = styled(MaskedInput as any)`
  border: 4px solid #9b0000 !important;
  border-radius: 8px !important;
`

const Wrapper = styled(Box)`
  position: relative;
  background-color: rgba(0, 0, 0, 1);
`

const SliderWrapper = styled(Box)`
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
`

const ControlsWrapper = styled(Box)`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
  padding-bottom: 10px;
`

const InternalControlsWrapper = styled(Box)`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  justify-items: center;
  align-items: center;
`

const BlueButton = styled(Button)`
  cursor: pointer;
  border: none;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.49px;
  color: var(--colors-buttons-primary-text);
  border-radius: 4px;
  background-color: var(--colors-buttons-primary-background);
  padding: 10px;
  margin: 4px 0;
  margin-right: 8px;
  margin-left: 8px;

  &.selected {
    color: #d7dddf;
    background-color: var(--colors-inputs-text-color);
  }
`

const WhiteButton = styled(Button)`
  cursor: pointer;
  border: none;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.49px;
  color: var(--colors-buttons-controls-text);
  border-radius: 4px;
  background-color: var(--colors-buttons-controls-background);
  padding: 10px;
  margin: 4px 0;
  margin-right: 8px;
  margin-left: 8px;

  &.selected {
    color: #d7dddf;
    background-color: var(--colors-inputs-text-color);
  }
`

const InputWrapper = styled.div`
  input {
    width: 100px;
    height: 40px;
    margin: 0 12px 0 0;
    padding: 11px 0 10px;
    border-radius: 8px;
    border: 0px;
    outline: none;
    text-align: center;
  }

  input:focus {
    outline: none;
  }
`

const SpeedControlWrapper = styled.div`
  display: inline-block;
  border: 1px solid;
  border-radius: 4px;
  padding: 10px 6px;
  background-color: #fff;
  box-shadow: 5px 5px 5px 5px rgba(f, 0, 0, 0.3);
`

export const PlayPauseButton = styled.button`
  margin: 20px;
  width: 80px;
  height: 40px;
  border-radius: 8px;
  align-self: center;
  justify-self: center;
  color: var(--colors-buttons-primary-text);
  background-color: var(--colors-buttons-controls-background);

  &.selected {
    color: #d7dddf;
    background-color: var(--colors-inputs-text-color);
  }
`

const SpeedItem = styled.div`
  border: none;
  padding: 3px;

  :hover {
    background-color: rgba(93, 186, 202, 0.4);
  }
`

export interface ControlsClipEditorProps {
  durationTime?: string
  elapsedTime: string
  played: number
  sliderVal: number
  playing: boolean
  playbackRate: number
  menuItems: any[]
  onSeek: (val: number) => void
  onPlayPause: () => void
  onSeekMouseDown: () => void
  onSeekMouseUp: (ev: any) => void
  onPlaybackRateChange: (rate: number) => void
  onSaveClip: (clip: any) => void
  clip?: Clip
  startTimeStamp?: number
  endTimeStamp?: number
  mode: ClipModes
  isDocument?: boolean
  isReel?: boolean
  onDownload?: () => void
  downloading?: boolean
  onClipCreate: () => void
  couldEdit: boolean
  setSelectedStartTime: any
  setSelectedEndTime: any
}

export const ControlsClipEditor = forwardRef(
  (
    {
      durationTime,
      elapsedTime,
      played,
      sliderVal,
      playing,
      playbackRate,
      menuItems,
      onSeek,
      onPlayPause,
      onSeekMouseDown,
      onSeekMouseUp,
      onPlaybackRateChange,
      onSaveClip,
      clip,
      startTimeStamp,
      endTimeStamp,
      mode,
      isDocument = false,
      isReel = false,
      onDownload,
      downloading,
      onClipCreate,
      couldEdit,
      setSelectedStartTime,
      setSelectedEndTime,
    }: ControlsClipEditorProps,
    ref,
  ) => {
    const format = useCallback((seconds: number) => {
      if (isNaN(seconds)) {
        return zeroTime
      }
      const date = new Date(seconds * 1000)
      const hh = date.getUTCHours().toString().padStart(2, '0')
      const mm = date.getUTCMinutes().toString().padStart(2, '0')
      const ss = date.getUTCSeconds().toString().padStart(2, '0')

      if (hh) {
        return `${hh}:${mm}:${ss}`
      }

      return `00:${mm}:${ss}`
    }, [])

    const formatFromSlider = useCallback(
      (percent: number, duration: string) => {
        const timer = duration.split(':')
        if (timer.length === 3) {
          const seconds =
            (Number(timer[0]) * 3600 +
              Number(timer[1]) * 60 +
              Number(timer[2])) *
            (Number(percent) / 10000)
          return format(seconds)
        }
        return zeroTime
      },
      [format],
    )

    const calcValue = useCallback((value: number, duration: string) => {
      const timer = duration.split(':')
      if (timer.length === 3) {
        const totalMilliseconds =
          (Number(timer[0]) * 3600 + Number(timer[1]) * 60 + Number(timer[2])) *
          1000
        const response = (value * 10000) / totalMilliseconds
        return response
      }
      return 0
    }, [])

    const [
      { firstValueSilder, lastValueSlider, startTime, endTime },
      setState,
    ] = useState({
      firstValueSilder: clip
        ? clip.startValue
        : startTimeStamp && durationTime
        ? calcValue(startTimeStamp, durationTime)
        : 0,
      lastValueSlider: clip
        ? clip.endValue
        : endTimeStamp && durationTime
        ? calcValue(endTimeStamp, durationTime)
        : 10000,
      startTime: clip
        ? clip.startTime
        : startTimeStamp && durationTime
        ? formatFromSlider(
            calcValue(startTimeStamp, durationTime),
            durationTime,
          )
        : zeroTime,
      endTime: clip
        ? clip.endTime
        : endTimeStamp && durationTime
        ? formatFromSlider(calcValue(endTimeStamp, durationTime), durationTime)
        : durationTime,
    })

    const prevDT = useRef(durationTime)

    useEffect(() => {
      if (
        prevDT.current == null &&
        durationTime != null &&
        startTimeStamp &&
        endTimeStamp
      ) {
        prevDT.current = durationTime
        const startTime = formatFromSlider(
          calcValue(startTimeStamp, durationTime),
          durationTime,
        )
        const endTime = formatFromSlider(
          calcValue(endTimeStamp, durationTime),
          durationTime,
        )
        setState({
          firstValueSilder: calcValue(startTimeStamp, durationTime),
          lastValueSlider: calcValue(endTimeStamp, durationTime),
          startTime,
          endTime,
        })
        setSelectedStartTime(startTime)
        setSelectedEndTime(endTime)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [durationTime])

    const totalDuration = useMemo(() => {
      const timeDuration = durationTime?.split(':')
      if (timeDuration) {
        return (
          Number(timeDuration[0]) * 3600 +
          Number(timeDuration[1]) * 60 +
          Number(timeDuration[2])
        )
      } else {
        return 0
      }
    }, [durationTime])

    const [displaySpeedControl, setDisplaySpeedControl] = useState(false)

    const handleFirstValueSlider = useCallback(
      (newVal: number) => {
        if (durationTime) {
          setState((state) => ({
            ...state,
            firstValueSilder: newVal,
            startTime: formatFromSlider(newVal, durationTime),
          }))
          setSelectedStartTime(formatFromSlider(newVal, durationTime))
        }
      },
      [durationTime, formatFromSlider, setSelectedStartTime],
    )

    const handleLastValueSlider = useCallback(
      (newVal: number) => {
        if (durationTime) {
          setState((state) => ({
            ...state,
            lastValueSlider: newVal,
            endTime: formatFromSlider(newVal, durationTime),
          }))
          setSelectedEndTime(formatFromSlider(newVal, durationTime))
        }
      },
      [durationTime, formatFromSlider, setSelectedEndTime],
    )

    const formatFromTime = useCallback(
      (newTime: string) => {
        let value: number | null = null
        const firstTime = newTime.split(':')
        if (firstTime.length === 3) {
          if (
            Number.isInteger(Number(firstTime[0])) &&
            Number.isInteger(Number(firstTime[1])) &&
            Number.isInteger(Number(firstTime[2]))
          ) {
            const timeValue =
              Number(firstTime[0]) * 3600 +
              Number(firstTime[1]) * 60 +
              Number(firstTime[2])
            value = (timeValue * 10000) / totalDuration
            value = value < 0 ? 0 : value
            value = value > 10000 ? 10000 : value
          }
        }
        return value ?? 0
      },
      [totalDuration],
    )

    const handleChangeStart = (ev: any) => {
      const newValue = ev.target.value
      setState((state) => ({
        ...state,
        startTime: newValue,
        firstValueSilder: formatFromTime(newValue),
      }))
    }

    const handleChangeEnd = (ev: any) => {
      const newValue = ev.target.value
      setState((state) => ({
        ...state,
        endTime: newValue,
        lastValueSilder: formatFromTime(newValue),
      }))
    }

    const handleKeyDown = (e: any) => {
      if (e.key == 'ArrowUp') {
        if (e.target.name === 'Start') {
          if (firstValueSilder + 60 <= lastValueSlider) {
            // can't go "past" end play time
            handleFirstValueSlider(firstValueSilder + 60)
          } else {
            handleFirstValueSlider(lastValueSlider)
          }
        } else if (e.target.name === 'End') {
          // can't go "past" end of video
          if (lastValueSlider + 60 <= totalDuration * 100) {
            handleLastValueSlider(lastValueSlider + 60)
          } else {
            handleLastValueSlider(totalDuration * 100)
          }
        }
        e.preventDefault()
      } else if (e.key === 'ArrowDown') {
        if (e.target.name === 'Start') {
          // can't go "past" 0
          if (firstValueSilder - 60 >= 0) {
            handleFirstValueSlider(firstValueSilder - 60)
          } else {
            handleFirstValueSlider(0)
          }
        } else if (e.target.name === 'End') {
          // can't go "past" the start play time
          if (lastValueSlider - 60 >= firstValueSilder) {
            handleLastValueSlider(lastValueSlider - 60)
          } else {
            handleLastValueSlider(firstValueSilder)
          }
        }
        e.preventDefault()
      }
    }

    const handleClickCreateClip = useCallback(() => {
      if (durationTime) {
        setState((state) => ({
          ...state,
          endTime: durationTime,
          lastValueSilder: formatFromTime(durationTime),
        }))
        onClipCreate()
      }
    }, [durationTime, formatFromTime, onClipCreate])

    const handlePlaybackRateChange = (newValue: number) => {
      setDisplaySpeedControl(false)
      onPlaybackRateChange(newValue)
    }

    const handleSaveClip = () => {
      const clip = {
        startValue: firstValueSilder,
        endValue: lastValueSlider,
        startTime: startTime,
        endTime: endTime,
      }
      onSaveClip(clip)
    }

    return (
      <Wrapper ref={ref as any}>
        <SliderWrapper>
          {mode === ClipModes.EditMode && (
            <DualSlider
              startValue={firstValueSilder}
              endValue={lastValueSlider}
              mainValue={played * 10000}
              onHandleFirstValue={handleFirstValueSlider}
              onHandleLastValue={handleLastValueSlider}
              onChangeMainValue={onSeek}
              onSeekMouseDown={onSeekMouseDown}
              onSeekMouseUp={onSeekMouseUp}
            />
          )}
          {mode !== ClipModes.EditMode && (
            <Slider
              mainValue={sliderVal}
              onChangeMainValue={onSeek}
              onSeekMouseDown={onSeekMouseDown}
              onSeekMouseUp={onSeekMouseUp}
            />
          )}
        </SliderWrapper>
        <ControlsWrapper>
          <div style={{ gridColumn: 1 }}>
            <InternalControlsWrapper>
              <PlayPauseButton
                style={{ gridColumn: 1, cursor: 'pointer' }}
                onClick={onPlayPause}
              >
                {!playing ? (
                  <PlayBlueIcon
                    width={17}
                    height={22}
                    style={{ justifySelf: 'center', alignSelf: 'center' }}
                  />
                ) : (
                  <PauseBlueIcon
                    width={20}
                    height={20}
                    style={{ justifySelf: 'center', alignSelf: 'center' }}
                  />
                )}
              </PlayPauseButton>
              <div style={{ gridColumn: 2 }}>
                <WhiteButton
                  onClick={() => setDisplaySpeedControl(!displaySpeedControl)}
                >
                  {
                    menuItems.find((item) => item.value === playbackRate)
                      .display
                  }
                </WhiteButton>
                <div
                  style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    bottom: '43px',
                  }}
                >
                  {displaySpeedControl && (
                    <SpeedControlWrapper>
                      {menuItems.map((item, idx) => {
                        return (
                          <SpeedItem
                            key={idx}
                            onClick={() => handlePlaybackRateChange(item.value)}
                            style={{
                              fontSize:
                                item.value === playbackRate ? '16' : '12',
                              fontWeight:
                                item.value === playbackRate ? 'bold' : 'normal',
                            }}
                          >
                            {item.display}
                          </SpeedItem>
                        )
                      })}
                    </SpeedControlWrapper>
                  )}
                </div>
              </div>
            </InternalControlsWrapper>
          </div>
          <div style={{ gridColumn: 2, color: '#fff' }}>
            {elapsedTime}/{durationTime}
          </div>
          <InputWrapper style={{ gridColumn: 3 }}>
            {mode === ClipModes.EditMode && (
              <div
                style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}
              >
                <StartTimeInput
                  mask={[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
                  placeholder={zeroTime}
                  value={startTime}
                  onChange={handleChangeStart}
                  onKeyDown={handleKeyDown}
                  name="Start"
                />
                <EndTimeInput
                  mask={[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
                  placeholder={zeroTime}
                  value={endTime}
                  onChange={handleChangeEnd}
                  onKeyDown={handleKeyDown}
                  name="End"
                />
                {!isDocument && (
                  <BlueButton onClick={handleSaveClip}>Save Clip</BlueButton>
                )}
              </div>
            )}
            {isDocument && onDownload && (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                <WhiteButton
                  onClick={onDownload}
                  loading={downloading ?? false}
                  disabled={downloading ?? false}
                >
                  Download
                </WhiteButton>
                {couldEdit && (
                  <BlueButton
                    onClick={handleClickCreateClip}
                    disabled={downloading ?? false}
                  >
                    Create Clip
                  </BlueButton>
                )}
              </div>
            )}
            {couldEdit && (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                <BlueButton
                  onClick={onClipCreate}
                  disabled={downloading ?? false}
                >
                  Edit Clip
                </BlueButton>
              </div>
            )}
            {isReel && <>{/* TBD */}</>}
          </InputWrapper>
        </ControlsWrapper>
      </Wrapper>
    )
  },
)

ControlsClipEditor.displayName = 'ControlsClipEditor'
