import 'nprogress/nprogress.css'
import '@aurelius/styles/theme.css'
import '@aurelius/styles/print.css'
import '@aurelius/styles/reset.css'
import '@aurelius/styles/anchor.css'
import 'tippy.js/dist/tippy.css'
import '@aurelius/styles/editorStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.min.css'
import '../components/ProjectReport/styles/layers.css'
import '../styles/custom.css'
import { ToastContainer } from 'react-toastify'
import { Favicons } from '../components/Favicons'
import { NextPage } from 'next'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import Head from 'next/head'
import { AppProps } from 'next/app'
import {
  QueryClientProvider,
  QueryClient,
  Hydrate,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Router } from 'next/router'
import NProgress from 'nprogress'
import { logout } from '../services/auth'
import { IntercomProvider } from 'react-use-intercom'
import { fonts } from '@aurelius/styles/fonts'
import { queryClientAtom } from 'jotai-tanstack-query'
import { useHydrateAtoms } from 'jotai/utils'
import { Provider } from 'jotai/react'
import { DevTools } from 'jotai-devtools'
import { intercomOptions } from '../helpers/intercom'
import { ChakraProvider } from '@chakra-ui/react'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { Theme } from '@aurelius/components'
import * as Sentry from '@sentry/nextjs'
import { sentryConfig } from '../utils/sentryConfig'
import { useEffectOnce } from 'react-use'

// import MaintenanceScreen from './MaintenanceScreen'

NProgress.configure({ showSpinner: false })

Router.events.on('routeChangeStart', (_url, { shallow }) => {
  if (!shallow) {
    // Perform actions only for non-shallow route changes
    NProgress.start()
  }
})
Router.events.on('routeChangeError', NProgress.done)
Router.events.on('routeChangeComplete', NProgress.done)

const fiveMinutes = 60_000 * 5

export type NextPageWithLayout<T = unknown> = NextPage<T> & {
  getLayout?: (page: ReactElement, pageProps: any) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp = (ctx: AppPropsWithLayout) => {
  useEffectOnce(() => {
    if (process.env.NODE_ENV === 'production') {
      Sentry.init(sentryConfig)
    }
  })

  const cache = createCache({ key: 'next' })
  const { Component, pageProps = {} } = ctx
  const getLayout = Component.getLayout || ((page) => page)

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchInterval: fiveMinutes,
            // setting it so that data is always fetched every refetchInterval
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchIntervalInBackground: true,
            refetchOnReconnect: 'always',
            staleTime: 0, // data will always be considered stale
            onError: (e) => {
              //@ts-expect-error status comes from our servers
              if (e?.status === 401) {
                if (pageProps.user) {
                  logout(false)
                }
              }
              return e
            },
          },
        },
      }),
  )
  const HydrateAtoms = ({ children }: React.PropsWithChildren) => {
    useHydrateAtoms([[queryClientAtom, queryClient]])
    return <>{children}</>
  }

  useEffect(() => {
    document.body.className = fonts
  }, [])

  return (
    <>
      <Head>
        <Favicons />
        <title>Aurelius</title>
      </Head>
      <CacheProvider value={cache}>
        <QueryClientProvider client={queryClient}>
          <Provider>
            <DevTools />
            <Hydrate state={pageProps.dehydratedState}>
              <HydrateAtoms>
                <IntercomProvider
                  shouldInitialize={!!process.env.INTERCOM_APP_ID}
                  appId={process.env.INTERCOM_APP_ID ?? ''}
                  autoBoot={!!process.env.INTERCOM_APP_ID}
                  autoBootProps={{
                    ...intercomOptions(pageProps?.user),
                  }}
                >
                  <ChakraProvider resetCSS={false} theme={Theme}>
                    {/* <MaintenanceScreen /> */}
                    {getLayout(<Component {...pageProps} />, pageProps)}
                    <ToastContainer position="bottom-left" />
                  </ChakraProvider>
                </IntercomProvider>
              </HydrateAtoms>
            </Hydrate>
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} position="top-right" />
        </QueryClientProvider>
      </CacheProvider>
    </>
  )
}

//! NOTE: DO NOT USE MyApp.getInitialProps in _app. It opts out of automatic static generation for pages that can have it.

export default MyApp
